.SiteButtonTextAbstract{
	@include rectangle(106px, 38px);
	@include textDecorator(white, $latomedium, 14px, auto 1px auto auto);
	border-bottom-style:solid;
	border-width:2px;
	float:left;
	text-align:center;
	padding-top:9px;
	text-transform:uppercase;
}
.SiteButtonIconAbstract{
	@include rectangle(40px,38px);
	border-bottom-style:solid;
	border-width:2px;
	float:left;
	text-align:center;
	padding-top:7px;
}
.SiteButtonAbstract{
	@include rectangle(147px, 40px);
	border:none;
	padding:0;
	margin:0;
	.text{
		@extend .SiteButtonTextAbstract;
	}
	.icon{
		@extend .SiteButtonIconAbstract;
	}
}

$inputBorder:#f5f5f5;
$inputText:#4e5157;

.ConvertorInput{
	@include rectangle(100px, 36px);
	@include textDecorator($inputText, $latolightitalic, 12px, 0);
	line-height:17px;
	border:1px solid $inputBorder;
	padding: 0 0 0 10px;
}

$infoTitle:#d9232e;

.RhombusTitleAbstract{
	@include textDecorator($infoTitle, $latobold, 20px, 0 0 15px 0);
	line-height: 25px;
	text-transform: uppercase;
	&:before{
		@extend .main-rhombus;
		@include square(15px!important);
		background: $infoTitle;
		content:"";
		display:inline-block;
		margin: 5px 10px 0 0;
		float:left;
	}
}