/** include framework dependencies **/

@import 'framework/_Factories';
@import 'framework/_AbstractClasses';
@import 'framework/_Constants';

/** end include dependencies **/

/** include sections **/

$guideTitle:#0f1d41;

@import 'sections/general/header';
@import 'sections/general/page_header';


/** end include sections **/

$newsHeaderBackground:#0d1939;
$newsBodyBackground:#f3f3f3;
$newsSubtitleBackground:#ce1e29;

.news-main-container{
	margin-bottom:40px;
}
.news-container{
	padding: 0 $halfPadd;
	.news-header{
		@include rectangle(100%, 74px);
		background:$newsHeaderBackground;
		padding-top: 24px;
		h3{
			@include textDecorator(white, $latomedium, 20px, 0 0 15px 0);
			line-height: 25px;
			text-transform: uppercase;
			text-align: center;
			
		}
	}
	.body{
		@include rectangle(100%, 526px);
		background:$newsBodyBackground;
		overflow:auto;
		.news-element{
			@include rectangle(260px, 140px);
			margin-left:52px;
			&:first-child{
				margin-top:70px;
			}
			.header{
				.image-container{
					top:-51px;
					z-index:2;
				}
				.title-container{
					@include rectangle(210px, 52px);
					width:210px;
					margin-left:52px;
					background:$newsHeaderBackground;
					z-index:1;
					padding-left:52px;
					h3{
						@include textDecorator(white, $latobold, 12px, 0 0 20px 0);
						line-height:16px;
						padding-top:8px;
						text-align:left;
						height:auto;
					}
				}
				.subtitle-container{
					@include rectangle(180px, 25px);
					margin-left:52px;
					clear:both;
					.triangle-corner{
						@include triangle($newsSubtitleBackground, 25px, 25px, 'top-right', 'isosceles');
					}
					.date-container{
						background:$newsSubtitleBackground;
						padding:3px 0 0 26px;
						@include rectangle(155px, 25px);
						p{
							@include textDecorator(white, $latomedium, 12px, 0 0 20px 0);

						}
					}
				}

			}
		}
	}
}

$objectiveTitle:#0c1e3f;
$objectiveTitleBackground:#f3f3f3;
$objectiveText:#58585a;
$iconBackground:#f3f3f3;

.objectives{
	padding: 0 $halfPadd;
	.objectives-list{
		li{
			.icon-container{
				background:$iconBackground;
				min-height:150px;
				.mission{
					@include square(57px);
					background:url(#{$homeUrl}mission_icon.png) 0 0 no-repeat;
					margin:3px;
				}
				.connect{
					@include rectangle(55px,42px);
					background:url(#{$homeUrl}connect_icon.png) 0 0 no-repeat;
					margin:0 0 0 4px;
				}
				.lobby{
					background:url(#{$homeUrl}lobby_icon.png) 0 0 no-repeat;
					@include rectangle(65px, 50px);
				}
				.share{
					@include rectangle(53px, 52px);
					background:url(#{$homeUrl}share_icon.png) 0 0 no-repeat;
					margin-left:5px;
				}
			}
			.text-container{
				margin-bottom:$fullMargin;
				padding-left:$fullPadd;
				h3{
					@include textDecorator($objectiveTitle, $latoregular, 20px, 0 0 15px 0);
					@include rectangle(250px, 38px);
					background:$objectiveTitleBackground;
					line-height: 25px;
					padding:7px 15px;
					text-transform:uppercase;
				}
				p{
					@include textDecorator($objectiveText, $latoregular, 14px, 0 0 0 0);
					line-height:18px;
				}
			}	
		}
		
	}
}
$joinSubtitle:#4e5157;
$joinTitle:#0f1d41;
$joinText:#58585a;


$buttonTextColor:#fff;
$buttonTextBackground:#d8232e;
$buttonTextBorderColor:#95181f;

$buttonIconColor:#fff;
$buttonIconBackground:#cc1d28;
$buttonIconBorderColor:#96181f;


.join-container{
	padding:0 $halfPadd;
	.subtitle{
		@include textDecorator($joinSubtitle, $latoregular, 20px, 0 0 0 0);
		line-height:25px;
		text-transform: uppercase;
		text-align: center;
	}
	h4{
		@include textDecorator($joinTitle, $latoregular, 36px, 0 0 $fullMargin 0);
		line-height:45px;
		text-transform: uppercase;
		text-align: center;
	}
	p{
		@include textDecorator($joinText, $latoregular, 14px, 0 0 15px 0);
		line-height:20px;
		text-align: center;
	}
	.join-icon{
		@include rectangle(39px, 42px);
		@include CenterContainer();
		background:url(#{$homeUrl}join_icon.png) 0 0 no-repeat;
		margin-bottom: $fullMargin;
	}
	.site-button{
		@extend .SiteButtonAbstract;
		@include CenterContainer();

		.text{
			@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
		}
		.icon{
			@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
			background:$buttonIconBackground url(#{$homeUrl}right_arrow.png) 16px 14px no-repeat;

		}
		clear:both;
	}
}

.goals-main-container{
	margin-bottom: $fullMargin;
}
$goalsTitle:#0f1d41;
$goalsText:#58585a;
$goalsTextRed:#df313c;

.goals{
	padding:0 $halfPadd;
	h3{
		@include textDecorator($goalsTitle, $latobold, 36px, 0 0 15px 0);
		line-height:45px;
		text-transform: uppercase;
	}
	.goals-list{
		li{
			p{
				@include textDecorator($goalsText, $latoregular, 14px, 0 0 15px 0);
				line-height:18px;
			}
			.red{
				color:$goalsTextRed;
				text-transform: uppercase;
			}
		}
	}
}

$red1First:#aa0d17;
$red1Last:#e2111f;

$red2First:#ce1e29;
$red2Last:#ff2836;

$red3First:#e53540;
$red3Last:#ff4655;

$bleumarineFirst:#0c1e3f;
$bleumarineLast:#102854;

$blueFirst:#22a1a9;
$blueLast:#2dd6e0;

$grayFirst:#4e5157;
$grayLast:#686c74;

.rhombus-goals-list{
	li{
		position:relative;
		margin:0 45px 0 0;
		&:first-child{
			margin-left:$halfPadd;
		}
		p{
			@include textDecorator(white, $latoregular, 14px, 0);
			@include CenterContainer();
			z-index:2;
			text-align:center;
			text-transform:uppercase;
			position:absolute;
			top:27px;
		}
		.rhombus{
			@extend .main-rhombus;
			@include square(74px);
			
		}
		.red1{
			@include TwoColorsGradient(left, to right, $red1First, $red1Last);
		}
		.red2{
			@include TwoColorsGradient(left, to right, $red2First, $red2Last);
		}
		.red3{
			@include TwoColorsGradient(left, to right, $red3First, $red3Last);
		}
		.bleumarine{
			@include TwoColorsGradient(left, to right, $bleumarineFirst, $bleumarineLast);
		}
		.blue{
			@include TwoColorsGradient(left, to right, $blueFirst, $blueLast);
		}
		.gray{
			@include TwoColorsGradient(left, to right, $grayFirst, $grayLast);
		}
	}
}

$fullBannerBackground:#0f1d41;

.full-banner{
	@include rectangle(100%, 75px);
	background:$fullBannerBackground;
	margin-bottom: 50px;

	h2{
		@include textDecorator(white, $latobold, 36px, 0);
		line-height:45px;
		text-align: center;
		padding-top: 14px;
		width: 100%;
		text-transform: uppercase;
	}
}


/** start membership section **/

$memberTitleColor:#58585a;
$memberEntityColor:#4e5157;
$honoraryTitle:#0f1d41;

$honoraryRhombusBackground:#151515;
$honoraryRhombusInnerBackground:#0b1c3a;

.membership-container{
	h2{
		@include textDecorator($honoraryTitle, $latobold, 36px, 0 0 35px 0);
		line-height:45px;
		text-transform: uppercase;
	}
}
.founding-members-container{
	.founding-list, .honorary-list{
		li{
			margin-bottom: $fullMargin;
			.member-info{
				h6{
					@include textDecorator($memberTitleColor, $latoregular, 20px, 0);
					line-height:25px;
				}
				.entity{
					@include textDecorator($memberEntityColor, $latoregular, 14px, 0);
					line-height:18px;
				}
			}
		}
	}
	.founding-list{
		li{
			.rhombus-holder{
				.icon-holder{
					@extend .main-rhombus;
					@include square(39px);
					.inner-rhombus{
						@include rectangle(34px, 36px);
						.members-icon{
							@include square(36px);
							transform:rotate(225deg);
							background:url(#{$homeUrl}small_member_icon.png) 10px 4px no-repeat;
						}
					}
				}
			}
		}
	}
	.honorary-list{
		li{
			.rhombus-holder{
				.icon-holder{
					@extend .main-rhombus;
					@include square(39px);
					background:$honoraryRhombusBackground;
					.inner-rhombus{
						background:$honoraryRhombusInnerBackground;
						@include rectangle(34px, 36px);
						.members-icon{
							@include square(36px);
							transform:rotate(225deg);
							background:url(#{$homeUrl}small_member_icon.png) 10px 4px no-repeat;
						}
					}
				}
			}
		}
	}

}

.membership-photo-container{
	img{
		top:-30px;
		left:-15px;
	}
}

/** end membership section **/