
/*
 *
 * PAGE TITLE STYLES
 *
 */

$titleColor:#0f1d41;
$paragraphColor:#4e5157;

.section-header{
	margin:60px 0 100px 0;
	h1{
		@include textDecorator($titleColor, $latobold, 36px, 0 0 $halfMargin 0);
		line-height: 45px;
		text-transform:uppercase;
		text-align:center;
		width:100%;
	}
	p{
		@include textDecorator($paragraphColor, $latoregular, 14px, 0 0 0 0);
		text-align:center;
		width:100%;
	}

}
