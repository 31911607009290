/** mixins **/

@import 'functions';
@import '../fonts';
@import "_ShapeFactories";
@import "_Transformers";
@import "_Transitions";
@import "_Decorators";
@import "_HTMLFactories";


@mixin expand(){
	@include rectangle(51px, 51px);
	background:url(./images/expand.png) 0 0 no-repeat;
	bottom:15px;
	right:15px;
}
@mixin slider(){
	transition-timing-function: ease-in-out;
	-webkit-transition-timing-function: ease-in-out;
	transition-property: all;
	-webkit-transition-property: all;
	-webkit-transition-duration: .5s; /* Safari */
	transition-duration: .5s;
	position:relative;
}
@mixin box_shadow_centered($horizontal, $vertical, $background){
	-webkit-box-shadow: 0px $horizontal $horizontal $vertical $shadow_color;
	-moz-box-shadow: 0px $horizontal $horizontal $vertical $shadow_color;
	box-shadow: 0px $horizontal $horizontal $vertical $shadow_color;
	background:$background;
}
@mixin box_shadow_centered_extended($horizontal, $vertical, $background, $shadow_color){
	-webkit-box-shadow: 0px $horizontal $horizontal $vertical $shadow_color;
	-moz-box-shadow: 0px $horizontal $horizontal $vertical $shadow_color;
	box-shadow: 0px $horizontal $horizontal $vertical $shadow_color;
	background:$background;
}
@mixin right_shadow($shadow_color){
	-webkit-box-shadow: 10px 0 5px 0px $shadow_color;
	box-shadow: 10px 0 5px 0px $shadow_color;
}
@mixin right_shadow_small($shadow_color, $height, $top, $right, $bottom, $left){
	width: 5px;
	height:$height;
	position:absolute;
	top:$top;
	right:$right;
	bottom:$bottom;
	left:$left;
	display:block;
	z-index:0;
	border-radius: 20px;
	-webkit-box-shadow: 10px 0 5px 0px $shadow_color;
	box-shadow: 10px 0 5px 0px $shadow_color;
	opacity:0.8;
}
@mixin shadow($shadow_color, $width, $height, $top, $right, $bottom, $left, $opacity){
	width: $width;
	height:$height;
	position:absolute;
	top:$top;
	right:$right;
	bottom:$bottom;
	left:$left;
	display:block;
	z-index:0;
	-moz-box-shadow: inset 0 -22px 45px 0px $shadow_color;
	-webkit-box-shadow: inset 0 -22px 45px 0px $shadow_color;
	box-shadow: inset 0 -22px 45px 0px $shadow_color;
	opacity:$opacity;
}
@mixin innerShadow($color, $size){
	-moz-box-shadow:    inset 0 0 $size $color;
	-webkit-box-shadow: inset 0 0 $size $color;
	box-shadow:         inset 0 0 $size $color;
}
@mixin inner_shadow_bottom($color){
	-moz-box-shadow: inset 0 -10px 20px -10px $color;
	-webkit-box-shadow: inset 0 -10px 20px -10px $color;
	box-shadow: inset 0 -10px 20px -10px $color;
}
@mixin outer_shadow($color){
	-webkit-box-shadow: 1px 2px 37px -9px $color;
	-moz-box-shadow: 1px 2px 37px -9px $color;
	box-shadow: 1px 2px 37px -9px $color;
}


@mixin TwoColorsGradient($from, $to, $firstColor, $lastColor){
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#48719b+4,44709c+95 */
	background: $firstColor; /* Old browsers */
	background: -moz-linear-gradient($from,  $firstColor 4%, $lastColor 95%); /* FF3.6-15 */
	background: -webkit-linear-gradient($from,  $firstColor 4%,$lastColor 95%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient($to,  $firstColor 4%, $lastColor 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$firstColor, endColorstr=$lastColor,GradientType=0 ); /* IE6-9 */
}


//create box shadow for all browsers
@mixin box_shadow($value){
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	box-shadow: $value;
}

@mixin transparentBackground($rgba, $hexa){
	background: $rgba;
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=$hexa, endColorstr=$hexa);
	-ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$hexa, endColorstr=$hexa);
}

@mixin GeneralMask($triangleWidth, $topLeft, $topRight, $bottomRight, $bottomLeft){
	@include rectangle(100%, 100%);
	.left-top-corner {
		@include triangle(white, $triangleWidth, $triangleWidth, 'top-left', 'isosceles');
		border-width:$triangleWidth $triangleWidth 0 0;
		z-index: 3;
		top: -1px;
		left: $topLeft;

	}
	.right-top-corner {
		@include triangle(white, $triangleWidth, $triangleWidth, 'top-right', 'isosceles');
		z-index: 1;
		top: -1px;
		right: $topRight;
	}

	.right-bottom-corner {
		@include triangle($mainBodyBackground, $triangleWidth, $triangleWidth, 'bottom-right', 'isosceles');
		z-index: 3;
		bottom: -1px;
		right: $bottomRight;
	}
	.left-bottom-corner {
		@include triangle(white, $triangleWidth, $triangleWidth, 'bottom-left', 'isosceles');
		z-index: 1;
		bottom: -1px;
		left: $bottomLeft;
	}
}

@mixin InformationHolder($categoryBackground, $mainTitleBackground, $latoregular){
	@include rectangle(246px, 63px);
	z-index: 2;
	bottom: 0px;
	right:0;
	.category {
		@include rectangle(100%, 39px);
		background: $categoryBackground;
		top: 0;
		left: 0;
		padding-top: 6px;
		padding-left: 29px;
		h6 {
			@include textDecorator(white, $latoregular, 12px, 0 0 0 0);
			line-height: 15px;
			width: 136px;
		}
	}
	.date {
		@include rectangle(100%, 24px);
		background: $mainTitleBackground;
		top: 39px;
		left: 0;
		padding-left: 29px;
		padding-top: 3px;
		p {
			@include textDecorator(white, $latoregular, 14px, 0 0 0 0);
			line-height: 18px;
		}
	}
}

/**end mixins **/