/** Translate **/

@mixin translate($left, $top){
	-webkit-transform: translate(-50%,0);
	-moz-transform: translate(-50%,0);
	-ms-transform: translate(-50%,0);
	-o-transform: translate(-50%,0);
	transform: translate(-50%,0);
}

/** Rotate **/

@mixin rotate($degrees){
	-webkit-transform: rotate($degrees);
	-moz-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform:rotate($degrees);
	transform: rotate($degrees);

}

/** Scale **/

@mixin scale($times, $origin){
	-webkit-transform:-webkit-scale($times);
	-moz-transform:-moz-scale($times);
	-ms-transform:-ms-scale($times);
	-o-transform:scale($times);
	transform:scale($times);
	transform-origin: $origin;
	-ms-transform-origin: $origin;
	-mos-transform-origin: $origin;
	-webkit-transform-origin: $origin;
}

/** Zoom - Scale Extended > 1 **/


@mixin ZoomOut($timeValue){

	-webkit-transition: all #{$timeValue}s ease;
	-moz-transition: all #{$timeValue}s ease;
	-ms-transition: all #{$timeValue}s ease;
	-o-transition: all #{$timeValue}s ease;
	transition: all #{$timeValue}s ease;
}

@mixin ZoomIn($scaleValue){

	-webkit-transform:scale($scaleValue);
	-moz-transform:scale($scaleValue);
	-ms-transform:scale($scaleValue);
	-o-transform:scale($scaleValue);
	transform:scale($scaleValue);

}